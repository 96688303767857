<template>
  <span v-if="show">
    <div class="AMback">
      <div class="AMdiv">
        <div class="AMtitle">
          <span>{{ title }}</span>
          <div class="closeicon" style="margin: 0px" @click="closeAM"></div>
        </div>
        <div class="AMcontent">
          <div class="content" v-html="content"></div>
        </div>
      </div>
      <div class="AMbackclose" @click="closeAM"></div>
    </div>
  </span>
</template>

<script>
import $ from "jquery";
import global from "../common";
export default {
  name: "TopA",
  components: {},
  props: {
    show: {
      type: Number,
      default: 0,
    },
    kind: String,
    w750: Number,
    lag: {
      type: String,
      default: "",
    },
  },
  watch: {
    lag: function (newVal, oldVal) {
      this.tlag = newVal; //这样就能在method使用props
      console.log("Watch：", this.tlag);
    },
    kind: function (newVal, oldVal) {
      this.kind = newVal; //这样就能在method使用props

      const that = this;
      // alert(this.kind);
      that.basedata = global.getbaseinfo();
      if (that.kind == "ys") {
        that.title = "趣野无限服务协议";
        that.content = that.basedata.yscontent;
      } else if (that.kind == "sj") {
        that.title = "肖像收集隐私政策";
        that.content = that.basedata.sjcontent;
      }
      console.log("Watch：", this.kind);
      
    },
  },
  data() {
    return {
      basedata: {},
      title: "",
      content: "",
    };
  },
  mounted() {

      
  },
  methods: {
    closeAM() {
      this.$emit("closeAM");
    },
  },
};
</script>

<style scoped></style>
