<template>
  <div
    :class="`loginreg lr flex-row ${show == 1 ? 'lrshowthis' : 'lrhidethis'} `"
    v-if="state"
  >
    <div class="lrdiv" v-if="step == 0">
      <div class="logindiv">
        <div class="line title">
          <img src="../assets/limgs/lr.png" style="width:142px height:28px;" />

          <div class="closeicon" @click="closeloginreg"></div>
        </div>
        <div class="line lt">
          <label class="t">手机号</label>
        </div>
        <div class="line lp">
          <a-input
            class="input"
            v-model="tel"
            placeholder="请输入手机号"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.25)"
          />
        </div>
        <div class="line lt">
          <label class="t">验证码</label>
        </div>
        <div class="line lp">
          <a-input
            class="input"
            v-model="ycode"
            placeholder="请输入收到的验证码"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.25)"
          />
          <div class="getyzm" v-if="count == 60" @click="getyzm">
            发送验证码
          </div>
          <div class="getyzm" v-else style="color: #ccc">{{ count }}秒</div>
        </div>
        <div class="line lt">
          <a-button
            class="submit"
            style="margin-top: 30px"
            :disabled="tel == '' || ycode == ''"
            @click="
              () => {
                submitlr();
              }
            "
          >
            确定
          </a-button>
        </div>
        <div class="line lt">
          <div class="yzmmsg">
            {{ yzmmsg }}
          </div>
        </div>
        <div class="msg">
          <a-checkbox
            @change="onReadChange"
            :style="w750 == 1 ? 'zoom:1.8;' : ''"
          >
            <span style="color: #000">已阅读同意</span>
          </a-checkbox>
          <span @click="openAM('ys')" style="cursor: pointer"
            >《趣野无限服务协议》</span
          >和
          <span
            @click="openAM('sj')"
            :style="`cursor: pointer; ${
              w750 == '1' ? 'margin-left:30px;' : ''
            } `"
            >《隐私条款》</span
          >
        </div>
      </div>
    </div>

    <div class="lrdiv2" v-if="step > 0">
      <div class="regdiv">
        <div class="line" style="margin-bottom: 10px">
          <label class="t">第{{ step }}步，共2步</label>
        </div>

        <template>
          <div class="line title" v-if="step == 1">
            <img src="../assets/limgs/r.png" style="width:178px height:28px;" />
          </div>
          <div class="line title" v-if="step == 2">
            <img
              src="../assets/limgs/r2.png"
              style="width:207px height:28px;"
            />
          </div>

          <div class="ildiv">
            <div class="swiper lrsc0">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div
                    class="inputList"
                    style="padding-bottom: 100px"
                    v-if="step == 1"
                  >
                    <!-- <van-button type="primary">主要按钮</van-button>
                    <van-cell
                      title="选择单个日期"
                      :value="date"
                      @click="vcshow = true"
                    />
                    <van-calendar v-model="vcshow" @confirm="onConfirm" style="zoom:2;" /> -->

                    <template v-for="(item, index) in inpbasedate">
                      <template v-if="!item.del">
                        <div :class="`inputItem`" :key="index">
                          <div class="line lt">
                            <span class="redx" v-if="item.required">*</span>
                            <label
                              :class="`t ${
                                checkshow == 1 &&
                                item.value == '' &&
                                item.required
                                  ? 'checkit'
                                  : ''
                              } `"
                              v-if="lag == 1"
                              v-html="item.name"
                            ></label>
                            <label
                              :class="`t ${
                                checkshow == 1 &&
                                item.value == '' &&
                                item.required
                                  ? 'checkit'
                                  : ''
                              } `"
                              v-else
                              v-html="item.cname"
                            ></label>
                          </div>
                          <div class="line lp">
                            <template v-if="item.kind == 'input'">
                              <a-input
                                v-if="lag == 1"
                                class="input"
                                v-model="inpbasedate[index].value"
                                :placeholder="item.placeholder"
                              />
                              <a-input
                                v-else
                                class="input"
                                v-model="inpbasedate[index].value"
                                :placeholder="item.cplaceholder"
                              />
                            </template>
                            <template v-if="item.kind == 'select'">
                              <template v-if="lag == '1'">
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.placeholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-else>
                                <!-- <van-picker
                                class="vanpicker"
                                title="标题"
                                show-toolbar
                                :columns="item.columns"
                                @change="()=>handleSelectChange(index)"
                              /> -->

                                <select
                                  v-if="w750 == 1"
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <option value="">
                                    {{ item.cplaceholder }}
                                  </option>
                                  <option
                                    v-for="item2 in item.values"
                                    :value="item2.id"
                                    :key="item2.id"
                                  >
                                    {{ item2.cname }}
                                  </option>
                                </select>
                                <a-select
                                  v-else
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.cname }}
                                  </a-select-option>
                                </a-select>
                              </template>
                            </template>

                            <template v-if="item.kind == 'selectcountry'">
                              <template v-if="lag == '1'">
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="
                                    () => {
                                      handleCountrySelectChange(index);
                                    }
                                  "
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.placeholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-else>
                                <select
                                  v-if="w750 == 1"
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="
                                    () => {
                                      handleCountrySelectChange(index);
                                    }
                                  "
                                >
                                  <option value="">
                                    <span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span>
                                  </option>
                                  <option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                    :value="item2.id"
                                  >
                                    {{ item2.cname }}
                                  </option>
                                </select>
                                <a-select
                                  v-else
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="
                                    () => {
                                      handleCountrySelectChange(index);
                                    }
                                  "
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.cname }}
                                  </a-select-option>
                                </a-select>
                              </template>
                            </template>

                            <template v-if="item.kind == 'selectPC'">
                              <template v-if="lag == '1'">
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.placeholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-else>
                                <select
                                  v-if="w750 == 1"
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <option value="">
                                    <span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span>
                                  </option>
                                  <option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                    :value="item2.id"
                                  >
                                    <template v-if="item2.cname != ''">
                                      {{ item2.cname }}
                                    </template>
                                    <template v-else>
                                      {{ item2.name }}
                                    </template>
                                  </option>
                                </select>
                                <a-select
                                  v-else
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    <template v-if="item2.cname != ''">
                                      {{ item2.cname }}
                                    </template>
                                    <template v-else>
                                      {{ item2.name }}
                                    </template>
                                  </a-select-option>
                                </a-select>
                              </template>
                            </template>

                            <template v-if="item.kind == 'date'">
                              <div
                                v-if="w750 == 1"
                                class="pickbirthday"
                                @click="openvdp"
                              >
                                {{
                                  inpbasedate[index].value != ""
                                    ? inpbasedate[index].value
                                    : "请选择"
                                }}
                              </div>
                              <a-date-picker
                                v-else
                                :default-value="
                                  item.value != ''
                                    ? moment(item.value, dateFormat)
                                    : ''
                                "
                                :format="dateFormat"
                                @change="onBirthdayChange"
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <div class="inputList" v-if="step == 2">
                    <template
                      v-for="(item, index) in inpLikedate.filter((item) => {
                        return item.del == false;
                      })"
                    >
                      <div
                        :class="`inputItem`"
                        :key="index"
                        style="width: 99%; border: none"
                      >
                        <div class="line lt">
                          <span class="redx" v-if="item.required">*</span>
                          <label
                            :class="`t ${
                              checkshow == 1 &&
                              item.value == '' &&
                              item.required
                                ? 'checkit'
                                : ''
                            } `"
                            v-if="lag == 1"
                            v-html="index + 1 + '.' + item.name"
                          ></label>
                          <label
                            :class="`t ${
                              checkshow == 1 &&
                              item.value == '' &&
                              item.required
                                ? 'checkit'
                                : ''
                            } `"
                            v-else
                            v-html="index + 1 + '.' + item.cname"
                          ></label>
                        </div>
                        <div class="line lb">
                          <template v-if="lag == 1">
                            <template v-for="(item2, index2) in item.values">
                              <div
                                :class="`lbitem ${
                                  item2.selected ? 'lbselected' : ''
                                }`"
                                :key="index2"
                                v-html="item2.name"
                                
                                  @click="
                                    () => {
                                      clicklike(
                                        item.code,
                                        index2,
                                        item2.code,
                                        item.multiple
                                      );
                                    }
                                  "

                              ></div>
                            </template>
                          </template>
                          <template v-else>
                            <template v-for="(item2, index2) in item.values">
                              <div
                                :class="`lbitem ${
                                  item2.selected ? 'lbselected' : ''
                                }`"
                                :key="index2"
                                v-html="item2.cname"

                                  @click="
                                    () => {
                                      clicklike(
                                        item.code,
                                        index2,
                                        item2.code,
                                        item.multiple
                                      );
                                    }
                                  "
                                  
                              ></div>
                            </template>

                            <div class="other" style="color: #000">
                              <template
                                v-if="
                                  item.values.filter((item3) => {
                                    return item3.code == '其他';
                                  }).length > 0
                                "
                              >
                                <template
                                  v-if="
                                    item.values.filter((item3) => {
                                      return item3.code == '其他';
                                    })[0].selected
                                  "
                                >
                                  <label>其他:</label>
                                  <a-input
                                    class="input"
                                    v-model="inpLikedate[index].other"
                                    :placeholder="''"
                                    :maxLength="100"
                                  />
                                </template>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="swiper-scrollbar"></div>
            </div>
          </div>

          <div
            class="line lt"
            style="
              justify-content: center;
              flex-direction: column;
              margin-top: 10px;
            "
          >
            <a-button
              class="submitNext"
              @click="
                () => {
                  submitlr();
                }
              "
            >
              下一步
            </a-button>
            <div
              class="wr"
              v-if="step == 1"
              style="font-size: 16px; line-height: 24px; margin-top: 10px"
            >
              <span style="color: #ff0000; position: relative; width: 100%"
                >*</span
              >请务必填写本人真实信息进行注册!
            </div>
          </div>
        </template>
        <!-- <template v-if="step == 2">
          

          <div class="ildiv">
            <div class="swiper lrsc1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  
                </div>
              </div>
            </div>
          </div>

          <div class="line lt" style="justify-content: center">
            <a-button
              class="submitNext"
              @click="
                () => {
                  submitlr();
                }
              "
            >
              下一步
            </a-button>
          </div>
        </template> -->
      </div>
    </div>

    <div class="vdp-div" v-show="vdpshow">
      <van-datetime-picker
        :value="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="vdpOnConfirm"
        @cancel="vdpCancel"
      />
    </div>
  </div>
</template>

<script>
const countries = require("../assets/json/countries.json");
const states = require("../assets/json/states.json");
import {
  fetchMemberinfo,
  fetchMemberyzm,
  fetchMember,
  updateMember,
  getYzm,
  idCardAuth,
  getSharecode,
  setCz,
} from "../api/index";
import $ from "jquery";
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";

import moment from "moment";

export default {
  name: "FooterA",
  props: {
    w750: Number,
    kind: String,
    show: {
      type: Number,
      default: 0,
    },
    lag: String,
    msg: {
      type: String,
      default: "",
    },
    state: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.myswiper0();
      console.log("Watch：", this.show);
    },
    kind: function (newVal, oldVal) {
      this.tkind = newVal; //这样就能在method使用props
      console.log("Watch：", this.tkind);
    },
  },
  data() {
    return {
      readed: false,

      isEmpty: 0,
      checkshow: 0,

      step: 0,

      vdpshow: false,
      minDate: new Date(1920, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),

      date: "",
      vcshow: false,

      dateFormat: "YYYY/MM/DD",
      yzmmsg: "",
      count: 60,
      yzm: "",
      countries: countries["RECORDS"],
      states: states["RECORDS"],
      // step: 0,
      tel: "",
      ycode: "",
      inpLikedate: [],

      inpbasedate: [],

      memberdata: [],

      lrswiper0: null,

      memberinfo: {},
    };
  },
  mounted() {
    let that = this;
    that.inpbasedate = global.inpbasedate;
    that.inpLikedate = global.inpLikedate;
    that.inpbasedate[3].values = countries["RECORDS"];
    // console.log("countries:", this.countries);
    this.myswiper0();

    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    month = month > 9 ? month : "0" + month;
    day = day < 10 ? "0" + day : day;

    this.maxDate = new Date(year, month, day);

    this.memberdata = global.getMemberinfo();

    console.log("LR mounted:", this.memberdata);

    if (this.memberdata.member_id != "") {
      that.tel = this.memberdata.member_tel;
      if (this.memberdata.isEmpty > 0) {
        this.step = 1;
        this.resetfetchMemberinfo();
        // alert(this.step);
      }
    }

    // alert("clear");
    // global.clearMemberInfo();

    setTimeout(() => {
      that.setluhucz();
    }, 1000);
  },
  methods: {
    setluhucz() {
      getSharecode({}).then((res) => {
        console.log("getSharecode:", res);
        let shareCode = res.results["shareCode"];
        let cz = res.results["cz"];
        if (cz == "" && shareCode.indexOf("90000") == 0) {
          // alert(shareCode + "__" + cz);
          this.$confirm({
            title: "请确认",
            content: "您是否为路虎车主",
            class: "confirmDiv",
            okText: "是",
            cancelText: "否",
            onOk() {
              setCz({ cz: 1 }).then((res) => {
                console.log("setCz:", res);
              });
            },
            onCancel() {
              setCz({ cz: 0 }).then((res) => {
                console.log("setCz:", res);
              });
            },
          });
        }
      });
    },
    onReadChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.readed = e.target.checked;
    },
    openAM(kind) {
      this.$emit("openAM", kind);
    },
    openvdp() {
      this.vdpshow = 1;
    },
    getNowDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      var today = year + "/" + month + "/" + day;
      return today;
    },
    FormatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      var today = year + "/" + month + "/" + day;

      console.log("year", year);
      console.log("month", month);
      console.log("day", day);

      console.log("today", today);

      return today;
    },
    vdpOnConfirm(e) {
      // console.log("vdpOnConfirm:",e);
      // console.log("this.currentDate:", e);
      let dateString = this.FormatDate(e);
      // console.log("dateString:", dateString);
      this.inpbasedate[2].value = dateString;
      this.vdpshow = 0;
    },
    vdpCancel() {
      this.vdpshow = 0;
    },
    myswiper0() {
      let that = this;

      setTimeout(() => {
        that.lrswiper0 = new Swiper(".lrsc0", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: true,
          mousewheel: true,
          // observer: true, //修改swiper自己或子元素时，自动初始化swiper
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // observeSlideChildren: true,
          scrollbar: {
            el: ".swiper-scrollbar",
          },
        });
        console.log("lrsc0");
      }, 500);
    },

    moment,
    onValuesChange(e) {
      console.log(`checked = ${e.target.checked}`);
      // this.readed = e.target.checked;
    },
    closeloginreg() {
      this.$emit("closelr");
    },
    // clicklike(index, index2, code, multiple) {
    //   if (multiple) {
    //     let selected = this.inpLikedate[index].values[index2].selected;
    //     this.inpLikedate[index].values[index2].selected = !selected;
    //     console.log(this.inpLikedate[index].values[index2].selected);
    //   } else {
    //     for (let i = 0; i < this.inpLikedate[index].values.length; i++) {
    //       this.inpLikedate[index].values[i].selected = false;
    //     }
    //     this.inpLikedate[index].values[index2].selected = true;
    //   }

    //   let arry = [];
    //   for (let i = 0; i < this.inpLikedate[index].values.length; i++) {
    //     let sed = this.inpLikedate[index].values[i].selected;
    //     if (sed) {
    //       arry.push(this.inpLikedate[index].values[i].code);
    //     }

    //     if (this.inpLikedate[index].values[i].code == "其他") {
    //       if (this.inpLikedate[index].values[i].selected == false) {
    //         this.inpLikedate[index].other = "";
    //       }
    //     }
    //   }
    //   let value = arry.join(",");
    //   this.inpLikedate[index].value = value;

    //   console.log("this.inpLikedate:", this.inpLikedate);
    // },


    clicklike(code, index2, code2 = "", multiple) {
      let index = 0;
      this.inpLikedate.map((item, idx) => {
        if (item.code == code) {
          index = idx;
        }
      });
      if (multiple) {
        let selected = this.inpLikedate[index].values[index2].selected;
        this.inpLikedate[index].values[index2].selected = !selected;
        console.log(this.inpLikedate[index].values[index2].selected);
      } else {
        for (let i = 0; i < this.inpLikedate[index].values.length; i++) {
          this.inpLikedate[index].values[i].selected = false;
        }
        this.inpLikedate[index].values[index2].selected = true;
      }

      let arry = [];
      for (let i = 0; i < this.inpLikedate[index].values.length; i++) {
        let sed = this.inpLikedate[index].values[i].selected;
        if (sed) {
          arry.push(this.inpLikedate[index].values[i].code);
        }
        if (this.inpLikedate[index].values[i].code == "其他") {
          if (this.inpLikedate[index].values[i].selected == false) {
            this.inpLikedate[index].other = "";
          }
        }
      }
      let value = arry.join(",");
      this.inpLikedate[index].value = value;
    },



    onBirthdayChange(date, dateString) {
      console.log(date, dateString);
      this.inpbasedate[2].value = dateString;
    },
    handleSelectChange() {
      console.log("inpbasedate:", this.inpbasedate);
    },
    handleCountrySelectChange(index) {
      let statesvalues = this.states.filter((item) => {
        return (
          parseInt(item["country_id"]) ==
          parseInt(this.inpbasedate[index].value)
        );
      });
      console.log("statesvalues:", statesvalues);
      this.inpbasedate[index + 1].values = statesvalues;
      this.inpbasedate[index + 1].value = "";
    },
    getyzm() {
      let that = this;

      if (!global.isMobile(this.tel)) {
        that.yzmmsg = "请检查手机号码";
        return false;
      }

      getYzm({
        phone: this.tel,
      }).then((res) => {
        console.log("getYzm:", res);
        let message = res.message;
        that.yzmmsg = "";
        console.log("message:", message);
        console.log("message:", message);
        if (message == 0) {
          that.yzmmsg = "验证码发送失败";
        }
        // that.yzm = res.results.yzm;
        // console.log("yzm:", that.yzm);
        that.startcount();
      });

      // let params = { tel: this.tel };
      // console.log("params:", params);
      // fetchMemberyzm(params).then((res) => {
      //   console.log("fetchMemberyzm:", res);
      //   let message = res.results.message;
      //   that.yzmmsg = "";
      //   console.log("message:", message);
      //   if (message == 0) {
      //     that.yzmmsg = "验证码发送失败";
      //   }
      //   that.yzm = res.results.yzm;
      //   console.log("yzm:", that.yzm);
      //   that.startcount();
      // });
    },
    startcount() {
      let that = this;
      let count = this.count;
      count = count - 1;
      that.count = count;
      setTimeout(() => {
        if (count > 0) {
          that.startcount();
        } else {
          that.count = 60;
        }
      }, 1000);
    },
    goNext() {
      console.log("goNext");
      let that = this;

      console.log(that.memberinfo);

      console.log("that.memberinfo.isEmpty:", that.memberinfo.isEmpty);

      if (that.memberinfo.isEmpty <= 0) {
        if (this.type == "baoming") {
          this.$emit("baoming");
        } else {
          location.reload();
        }
        return false;
      }

      let step = this.step;
      console.log("step:", step + 1 > 2);
      if (step + 1 > 2) {
        that.$emit("closelr");
        // window.location.href = `#/pcenter/${this.lag}`;
        // windwo.location.reload();
        if (this.type == "baoming") {
          this.$emit("baoming");
        } else {
          location.reload();
        }
        return false;
      }
      this.step = step + 1;
      this.checkshow = 0;
      if (this.step == 1) {
        this.myswiper0();
      }
      if (this.step == 2) {
        setTimeout(() => {
          that.lrswiper0.update();
        }, 500);
      }
    },
    fetchMemberinfo() {
      let that = this;
      let params = { tel: this.tel, yzm: this.ycode };
      console.log("params:", params);
      fetchMember(params).then((res) => {
        let message = res.results.message;
        if (message == 2) {
          that.yzmmsg = "验证码错误";
          return false;
        } else if (message == 3) {
          that.yzmmsg = "电话不存在";
          return false;
        } else if (message == 4) {
          that.yzmmsg = "用户被锁定";
          return false;
        } else if (message == 1) {
          let data = res.results;
          global.setMemberInfo(data);

          that.inpbasedate[0].value = data["nickName"];
          that.inpbasedate[1].value = data["sex"];
          that.inpbasedate[2].value = data["birthday"];
          that.inpbasedate[3].value = data["country"];
          that.inpbasedate[4].value = data["PC"];
          that.inpbasedate[5].value = data["name"];
          that.inpbasedate[6].value = data["idtype"];
          that.inpbasedate[7].value = data["idnumber"];
          that.inpbasedate[8].value = data["industry"];
          that.inpbasedate[9].value = data["company"];
          that.inpbasedate[10].value = data["duties"];
          that.inpbasedate[11].value = data["as"];
          that.inpbasedate[12].value = data["years"];

          let statesvalues = that.states.filter((item) => {
            return (
              parseInt(item["country_id"]) ==
              parseInt(that.inpbasedate[3].value)
            );
          });
          // console.log("statesvalues:", statesvalues);
          that.inpbasedate[4].values = statesvalues;

          that.memberdata = res.results;

          that.memberinfo = global.getMemberinfo();

          if (that.inpbasedate[2].value != "") {
            console.log(that.inpbasedate[2].value);
            let dateary = that.inpbasedate[2].value.split("/");
            console.log(dateary[0]);
            console.log(dateary[1]);
            console.log(dateary[2]);
            that.currentDate = new Date(
              parseInt(dateary[0]),
              parseInt(dateary[1]) - 1,
              parseInt(dateary[2])
            );
          }

          console.log("that.memberdata:", that.memberdata);

          that.goNext();
        }
      });
    },

    resetfetchMemberinfo() {
      let that = this;
      let params = { tel: global.getMemberinfo().member_tel };
      console.log("params:", params);
      fetchMemberinfo(params).then((res) => {
        let message = res.results.message;
        if (message == 1) {
          let data = res.results;
          that.memberdata = res.results;

          global.setMemberInfo(data);

          that.memberinfo = global.getMemberinfo();

          that.inpbasedate[0].value = data["nickName"];
          that.inpbasedate[1].value = data["sex"];
          that.inpbasedate[2].value = data["birthday"];
          that.inpbasedate[3].value = data["country"];
          that.inpbasedate[4].value = data["PC"];
          that.inpbasedate[5].value = data["name"];
          that.inpbasedate[6].value = data["idtype"];
          that.inpbasedate[7].value = data["idnumber"];
          that.inpbasedate[8].value = data["industry"];
          that.inpbasedate[9].value = data["company"];
          that.inpbasedate[10].value = data["duties"];
          that.inpbasedate[11].value = data["as"];
          that.inpbasedate[12].value = data["years"];

          // console.log("that.memberdata:",that.memberdata);


          that.inpLikedate.map((item, index) => {
            if (item.code == "like0") {
              console.log("like0",index,that.memberdata["like0"]);
              that.inpLikedate[index].value = that.memberdata["like0"];
              that.inpLikedate[index].other = that.memberdata["other0"];
            }
            if (item.code == "like1") {
              console.log("like1",index,that.memberdata["like1"]);
              that.inpLikedate[index].value = that.memberdata["like1"];
              that.inpLikedate[index].other = that.memberdata["other1"];
            }
            if (item.code == "like2") {
              console.log("like2",index,that.memberdata["like2"]);
              that.inpLikedate[index].value = that.memberdata["like2"];
              that.inpLikedate[index].other = that.memberdata["other2"];
            }
            if (item.code == "like3") {
              console.log("like3",index,that.memberdata["like3"]);
              that.inpLikedate[index].value = that.memberdata["like3"];
              that.inpLikedate[index].other = that.memberdata["other3"];
            }
            if (item.code == "like4") {
              console.log("like4",index,that.memberdata["like4"]);
              that.inpLikedate[index].value = that.memberdata["like4"];
            }
            if (item.code == "like5") {
              console.log("like5",index,that.memberdata["like5"]);
              that.inpLikedate[index].value = that.memberdata["like5"];
            }
            if (item.code == "like6") {
              console.log("like6",index,that.memberdata["like6"]);
              that.inpLikedate[index].value = that.memberdata["like6"];
              that.inpLikedate[index].other = that.memberdata["other6"];
            }
          });

          // that.inpLikedate[0].value = data["like0"];
          // that.inpLikedate[1].value = data["like1"];
          // that.inpLikedate[2].value = data["like2"];
          // that.inpLikedate[3].value = data["like3"];
          // that.inpLikedate[4].value = data["like4"];
          // that.inpLikedate[5].value = data["like5"];
          // that.inpLikedate[6].value = data["like6"];

          // that.inpLikedate[0].other = data["other0"];
          // that.inpLikedate[1].other = data["other1"];
          // that.inpLikedate[2].other = data["other2"];
          // that.inpLikedate[3].other = data["other3"];
          // that.inpLikedate[6].other = data["other6"];

          console.log("1 that.inpLikedate:", that.inpLikedate);


          for (var i = 0; i < that.inpLikedate.length; i++) {
            let item = that.inpLikedate[i].values;
            for (var l = 0; l < item.length; l++) {
              // console.log(that.memberdata[`like${i}`]);
              if (
                that.inpLikedate[i].value.indexOf(
                  that.inpLikedate[i].values[l].code
                ) > -1
              ) {
                that.inpLikedate[i].values[l].selected = true;
              }
            }
          }


          let statesvalues = that.states.filter((item) => {
            return (
              parseInt(item["country_id"]) ==
              parseInt(that.inpbasedate[3].value)
            );
          });
          // console.log("statesvalues:", statesvalues);
          that.inpbasedate[4].values = statesvalues;

          if (that.inpbasedate[2].value != "") {
            console.log(that.inpbasedate[2].value);
            let dateary = that.inpbasedate[2].value.split("/");
            console.log(dateary[0]);
            console.log(dateary[1]);
            console.log(dateary[2]);
            that.currentDate = new Date(
              parseInt(dateary[0]),
              parseInt(dateary[1]) - 1,
              parseInt(dateary[2])
            );
          }
        }
      });
    },

    submitlr() {
      let that = this;
      if (that.step == 0) {
        if (this.readed) {
          that.fetchMemberinfo();
        } else {
          that.yzmmsg = "请阅读并同意《趣野无限服务协议》";
        }
      } else if (that.step == 1) {
        let params = { tel: that.tel, datas: that.inpbasedate };
        console.log("params:", params);

        const h = this.$createElement;

        that.isEmpty = 0;

        that.inpbasedate.map((item, index) => {
          if (item.value == "" && item.required && !item.del) {
            that.isEmpty++;
          }
        });

        if (that.isEmpty > 0) {
          this.$info({
            title: "注意",
            content: h("div", {}, [h("p", "请完善表单数据！")]),
            onOk() {},
          });
          that.checkshow = 1;
          return false;
        }

        updateMember(params).then((res) => {
          console.log("updateMember:", res);

          that.inpLikedate.map((item, index) => {
            if (item.code == "like0") {
              console.log("like0",index,that.memberdata["like0"]);
              that.inpLikedate[index].value = that.memberdata["like0"];
              that.inpLikedate[index].other = that.memberdata["other0"];
            }
            if (item.code == "like1") {
              console.log("like1",index,that.memberdata["like1"]);
              that.inpLikedate[index].value = that.memberdata["like1"];
              that.inpLikedate[index].other = that.memberdata["other1"];
            }
            if (item.code == "like2") {
              console.log("like2",index,that.memberdata["like2"]);
              that.inpLikedate[index].value = that.memberdata["like2"];
              that.inpLikedate[index].other = that.memberdata["other2"];
            }
            if (item.code == "like3") {
              console.log("like3",index,that.memberdata["like3"]);
              that.inpLikedate[index].value = that.memberdata["like3"];
              that.inpLikedate[index].other = that.memberdata["other3"];
            }
            if (item.code == "like4") {
              console.log("like4",index,that.memberdata["like4"]);
              that.inpLikedate[index].value = that.memberdata["like4"];
            }
            if (item.code == "like5") {
              console.log("like5",index,that.memberdata["like5"]);
              that.inpLikedate[index].value = that.memberdata["like5"];
            }
            if (item.code == "like6") {
              console.log("like6",index,that.memberdata["like6"]);
              that.inpLikedate[index].value = that.memberdata["like6"];
              that.inpLikedate[index].other = that.memberdata["other6"];
            }
          });

          // that.inpLikedate[0].value = that.memberdata["like0"];
          // that.inpLikedate[1].value = that.memberdata["like1"];
          // that.inpLikedate[2].value = that.memberdata["like2"];
          // that.inpLikedate[3].value = that.memberdata["like3"];
          // that.inpLikedate[4].value = that.memberdata["like4"];
          // that.inpLikedate[5].value = that.memberdata["like5"];
          // that.inpLikedate[6].value = that.memberdata["like6"];

          // that.inpLikedate[0].other = that.memberdata["other0"];
          // that.inpLikedate[1].other = that.memberdata["other1"];
          // that.inpLikedate[2].other = that.memberdata["other2"];
          // that.inpLikedate[3].other = that.memberdata["other3"];
          // that.inpLikedate[6].other = that.memberdata["other6"];

          console.log("that.inpLikedate:", that.inpLikedate);

          console.log("that.memberdata:", that.memberdata);

          for (var i = 0; i < that.inpLikedate.length; i++) {
            let item = that.inpLikedate[i].values;
            for (var l = 0; l < item.length; l++) {
              // console.log(that.memberdata[`like${i}`]);
              if (
                that.inpLikedate[i].value.indexOf(
                  that.inpLikedate[i].values[l].code
                ) > -1
              ) {
                that.inpLikedate[i].values[l].selected = true;
              }
            }
          }

          that.resetfetchMemberinfo();

          if (that.inpbasedate[6].value == "0") {
            let postdata = {
              cardNo: that.inpbasedate[7].value,
              realName: encodeURIComponent(that.inpbasedate[5].value),
            };
            console.log("onIdCardAuth:", postdata);

            idCardAuth(postdata).then((res) => {
              console.log("idCardAuth:", res);

              let results = res.results;

              if (results.message == "0") {
                //请核实您的姓名以及身份证号码
                this.$info({
                  title: "注意",
                  content: h("div", {}, [
                    h("p", `请核实您的姓名以及身份证号码!`),
                  ]),
                  onOk() {},
                });
              }
              if (results.message == "1") {
                global.setauth("1");
                that.goNext();
              }
              if (results.message == "2") {
                //短时间验证次数过多，请等待30分钟后再试
                this.$info({
                  title: "注意",
                  content: h("div", {}, [
                    h(
                      "p",
                      `短时间验证次数过多，请等待${parseInt(
                        results.authtime
                      )}分钟后再试!`
                    ),
                  ]),
                  onOk() {},
                });
              }
              if (results.message == "-1") {
                console.log("已经通过审核");
                that.goNext();
              }
            });
          } else {
            that.goNext();
          }
        });

        // let postdata = {
        //   cardNo: this.memberdata.member_idnumber,
        //   realName: encodeURIComponent(this.memberdata.member_name),
        // };

        // console.log("onIdCardAuth:", postdata);
      } else if (that.step == 2) {
        that.isEmpty = 0;

        that.inpLikedate.map((item, index) => {
          if (item.value == "" && item.required && !item.del) {
            that.isEmpty++;
          }
        });

        console.log("that.isEmpty:", that.isEmpty);

        if (that.isEmpty > 0) {
          const h = this.$createElement;
          this.$info({
            title: "注意",
            content: h("div", {}, [h("p", "请完善表单数据！")]),
            onOk() {},
          });
          that.checkshow = 1;
          return false;
        }

        let params = { tel: that.tel, datas: that.inpLikedate };
        console.log("params:", params);
        updateMember(params).then((res) => {
          console.log("updateMember:", res);
          that.resetfetchMemberinfo();

          // return false;
          that.goNext();
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
