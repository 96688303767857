<template>
  <div
    :class="`loginreg lr flex-row ${show == 1 ? 'lrshowthis' : 'lrhidethis'} `"
    v-if="state"
  >
    <div class="lrdiv2">
      <div class="regdiv">
        <div class="line title" style="position: absolute; z-index: 99">
          <!-- <img src="../assets/limgs/gsxx.png" style="width:118px height:28px;" /> -->
          <div class="closeicon" @click="closebaoming"></div>
        </div>

        <!-- <div class="line title" v-if="index == 6">
          <img src="../assets/limgs/lxrxx.png" style="width:118px height:28px;" />
        </div> -->

        <div class="ildiv" style="height: calc(100% - 120px);">
          <div class="line title" style="width: 90%">
            <img
              src="../assets/limgs/gsxx.png"
              style="width:118px height:28px;"
            />
          </div>
          <div class="inputList">
            <template v-for="(item, index) in inpbmdate">
              <template v-if="!item.del">
                <div class="inputItem" :key="index" v-if="index < 6">
                  <div class="line lt">
                    <span class="redx" v-if="item.required">*</span>
                    <label
                      :class="`t ${
                        checkshow == 1 && item.value == '' && item.required
                          ? 'checkit'
                          : ''
                      } `"
                      v-if="lag == 1"
                      v-html="item.name"
                    ></label>
                    <label
                      :class="`t ${
                        checkshow == 1 && item.value == '' && item.required
                          ? 'checkit'
                          : ''
                      } `"
                      v-else
                      v-html="item.cname"
                    ></label>
                  </div>
                  <div class="line lp">
                    <template v-if="item.kind == 'input'">
                      <a-input
                        v-if="lag == 1"
                        class="input"
                        :maxLength="100"
                        v-model="inpbmdate[index].value"
                        :placeholder="item.placeholder"
                      />
                      <a-input
                        v-else
                        class="input"
                        :maxLength="100"
                        v-model="inpbmdate[index].value"
                        :placeholder="item.cplaceholder"
                      />
                    </template>
                    <template v-if="item.kind == 'select'">
                      <template v-if="lag == '1'">
                        <a-select
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleSelectChange"
                        >
                          <a-select-option key=""
                            ><span class="lightoption">{{
                              item.placeholder
                            }}</span></a-select-option
                          >
                          <a-select-option
                            v-for="item2 in item.values"
                            :key="item2.id"
                          >
                            {{ item2.name }}
                          </a-select-option>
                        </a-select>
                      </template>
                      <template v-else>
                        <select
                          v-if="w750 == 1"
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleSelectChange"
                        >
                          <option value="">
                            {{ item.cplaceholder }}
                          </option>
                          <option
                            v-for="item2 in item.values"
                            :value="item2.id"
                            :key="item2.id"
                          >
                            {{ item2.cname }}
                          </option>
                        </select>
                        <a-select
                          v-else
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleSelectChange"
                        >
                          <a-select-option key=""
                            ><span class="lightoption">{{
                              item.cplaceholder
                            }}</span></a-select-option
                          >
                          <a-select-option
                            v-for="item2 in item.values"
                            :key="item2.id"
                          >
                            {{ item2.cname }}
                          </a-select-option>
                        </a-select>
                      </template>
                    </template>

                    <template v-if="item.kind == 'selectcountry'">
                      <template v-if="lag == '1'">
                        <a-select
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleCountrySelectChange"
                        >
                          <a-select-option key=""
                            ><span class="lightoption">{{
                              item.placeholder
                            }}</span></a-select-option
                          >
                          <a-select-option
                            v-for="item2 in item.values"
                            :key="item2.id"
                          >
                            {{ item2.name }}
                          </a-select-option>
                        </a-select>
                      </template>
                      <template v-else>
                        <select
                          v-if="w750 == 1"
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleCountrySelectChange"
                        >
                          <option value="">
                            <span class="lightoption">{{
                              item.cplaceholder
                            }}</span>
                          </option>
                          <option
                            v-for="item2 in item.values"
                            :key="item2.id"
                            :value="item2.id"
                          >
                            {{ item2.cname }}
                          </option>
                        </select>
                        <a-select
                          v-else
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleCountrySelectChange"
                        >
                          <a-select-option key=""
                            ><span class="lightoption">{{
                              item.cplaceholder
                            }}</span></a-select-option
                          >
                          <a-select-option
                            v-for="item2 in item.values"
                            :key="item2.id"
                          >
                            {{ item2.cname }}
                          </a-select-option>
                        </a-select>
                      </template>
                    </template>

                    <template v-if="item.kind == 'selectPC'">
                      <template v-if="lag == '1'">
                        <a-select
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleSelectChange"
                        >
                          <a-select-option key=""
                            ><span class="lightoption">{{
                              item.placeholder
                            }}</span></a-select-option
                          >
                          <a-select-option
                            v-for="item2 in item.values"
                            :key="item2.id"
                          >
                            {{ item2.name }}
                          </a-select-option>
                        </a-select>
                      </template>
                      <template v-else>
                        <select
                          v-if="w750 == 1"
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleSelectChange"
                        >
                          <option value="">
                            <span class="lightoption">{{
                              item.cplaceholder
                            }}</span>
                          </option>
                          <option
                            v-for="item2 in item.values"
                            :key="item2.id"
                            :value="item2.id"
                          >
                            <template v-if="item2.cname != ''">
                              {{ item2.cname }}
                            </template>
                            <template v-else>
                              {{ item2.name }}
                            </template>
                          </option>
                        </select>
                        <a-select
                          v-else
                          v-model="inpbmdate[index].value"
                          class="select"
                          @change="handleSelectChange"
                        >
                          <a-select-option key=""
                            ><span class="lightoption">{{
                              item.cplaceholder
                            }}</span></a-select-option
                          >
                          <a-select-option
                            v-for="item2 in item.values"
                            :key="item2.id"
                          >
                            <template v-if="item2.cname != ''">
                              {{ item2.cname }}
                            </template>
                            <template v-else>
                              {{ item2.name }}
                            </template>
                          </a-select-option>
                        </a-select>
                      </template>
                    </template>

                    <template v-if="item.kind == 'date'">
                      <a-date-picker
                        :default-value="moment(item.value, dateFormat)"
                        :format="dateFormat"
                        @change="onBirthdayChange"
                      />
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <div class="line title" style="margin-top: 50px; width: 90%">
            <img
              src="../assets/limgs/lxrxx.png"
              style="width:148px height:28px;"
            />
          </div>
          <div class="inputList">
            <template v-for="(item, index) in inpbmdate">
              <div class="inputItem" :key="index" v-if="index >= 6">
                <div class="line lt">
                  <span class="redx" v-if="item.required">*</span>
                  <label
                    :class="`t ${
                      checkshow == 1 &&
                      ((item.value == '' && item.required) ||
                        (wrgtel && item.code == 'tel') ||
                        (wrgemail && item.code == 'email'))
                        ? 'checkit'
                        : ''
                    } `"
                    v-if="lag == 1"
                    v-html="item.name"
                  ></label>
                  <label
                    :class="`t ${
                      checkshow == 1 &&
                      ((item.value == '' && item.required) ||
                        (wrgtel && item.code == 'tel') ||
                        (wrgemail && item.code == 'email'))
                        ? 'checkit'
                        : ''
                    } `"
                    v-else
                    v-html="item.cname"
                  ></label>
                </div>
                <div class="line lp">
                  <template v-if="item.kind == 'input'">
                    <a-input
                      v-if="lag == 1"
                      class="input"
                      :maxLength="100"
                      v-model="inpbmdate[index].value"
                      :placeholder="item.placeholder"
                    />
                    <a-input
                      v-else
                      class="input"
                      :maxLength="100"
                      v-model="inpbmdate[index].value"
                      :placeholder="item.cplaceholder"
                    />
                  </template>
                  <template v-if="item.kind == 'select'">
                    <template v-if="lag == '1'">
                      <a-select
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleSelectChange"
                      >
                        <a-select-option key=""
                          ><span class="lightoption">{{
                            item.placeholder
                          }}</span></a-select-option
                        >
                        <a-select-option
                          v-for="item2 in item.values"
                          :key="item2.id"
                        >
                          {{ item2.name }}
                        </a-select-option>
                      </a-select>
                    </template>
                    <template v-else>
                      <select
                        v-if="w750 == 1"
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleSelectChange"
                      >
                        <option value="">
                          {{ item.cplaceholder }}
                        </option>
                        <option
                          v-for="item2 in item.values"
                          :value="item2.id"
                          :key="item2.id"
                        >
                          {{ item2.cname }}
                        </option>
                      </select>
                      <a-select
                        v-else
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleSelectChange"
                      >
                        <a-select-option key=""
                          ><span class="lightoption">{{
                            item.cplaceholder
                          }}</span></a-select-option
                        >
                        <a-select-option
                          v-for="item2 in item.values"
                          :key="item2.id"
                        >
                          {{ item2.cname }}
                        </a-select-option>
                      </a-select>
                    </template>
                  </template>

                  <template v-if="item.kind == 'selectcountry'">
                    <template v-if="lag == '1'">
                      <a-select
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleCountrySelectChange"
                      >
                        <a-select-option key=""
                          ><span class="lightoption">{{
                            item.placeholder
                          }}</span></a-select-option
                        >
                        <a-select-option
                          v-for="item2 in item.values"
                          :key="item2.id"
                        >
                          {{ item2.name }}
                        </a-select-option>
                      </a-select>
                    </template>
                    <template v-else>
                      <select
                        v-if="w750 == 1"
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleCountrySelectChange"
                      >
                        <option value="">
                          <span class="lightoption">{{
                            item.cplaceholder
                          }}</span>
                        </option>
                        <option
                          v-for="item2 in item.values"
                          :key="item2.id"
                          :value="item2.id"
                        >
                          {{ item2.cname }}
                        </option>
                      </select>
                      <a-select
                        v-else
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleCountrySelectChange"
                      >
                        <a-select-option key=""
                          ><span class="lightoption">{{
                            item.cplaceholder
                          }}</span></a-select-option
                        >
                        <a-select-option
                          v-for="item2 in item.values"
                          :key="item2.id"
                        >
                          {{ item2.cname }}
                        </a-select-option>
                      </a-select>
                    </template>
                  </template>

                  <template v-if="item.kind == 'selectPC'">
                    <template v-if="lag == '1'">
                      <a-select
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleSelectChange"
                      >
                        <a-select-option key=""
                          ><span class="lightoption">{{
                            item.placeholder
                          }}</span></a-select-option
                        >
                        <a-select-option
                          v-for="item2 in item.values"
                          :key="item2.id"
                        >
                          {{ item2.name }}
                        </a-select-option>
                      </a-select>
                    </template>
                    <template v-else>
                      <select
                        v-if="w750 == 1"
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleSelectChange"
                      >
                        <option value="">
                          <span class="lightoption">{{
                            item.cplaceholder
                          }}</span>
                        </option>
                        <option
                          v-for="item2 in item.values"
                          :key="item2.id"
                          :value="item2.id"
                        >
                          <template v-if="item2.cname != ''">
                            {{ item2.cname }}
                          </template>
                          <template v-else>
                            {{ item2.name }}
                          </template>
                        </option>
                      </select>
                      <a-select
                        v-else
                        v-model="inpbmdate[index].value"
                        class="select"
                        @change="handleSelectChange"
                      >
                        <a-select-option key=""
                          ><span class="lightoption">{{
                            item.cplaceholder
                          }}</span></a-select-option
                        >
                        <a-select-option
                          v-for="item2 in item.values"
                          :key="item2.id"
                        >
                          <template v-if="item2.cname != ''">
                            {{ item2.cname }}
                          </template>
                          <template v-else>
                            {{ item2.name }}
                          </template>
                        </a-select-option>
                      </a-select>
                    </template>
                  </template>

                  <template v-if="item.kind == 'date'">
                    <a-date-picker
                      :default-value="moment(item.value, dateFormat)"
                      :format="dateFormat"
                      @change="onBirthdayChange"
                    />
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="line lt" style="justify-content: center">
          <a-button
            class="submitNext"
            @click="
              () => {
                submitlr();
              }
            "
          >
            下一步
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const countries = require("../assets/json/countries.json");
const states = require("../assets/json/states.json");
import { fetchMemberyzm, fetchMember, updateMember, addBm } from "../api/index";
import $ from "jquery";
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";

import moment from "moment";
export default {
  name: "FooterA",
  props: {
    w750: Number,
    kind: String,
    bmid: String,
    bkind: String,
    show: {
      type: Number,
      default: 0,
    },
    lag: String,
    msg: {
      type: String,
      default: "",
    },
    state: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dateFormat: "YYYY/MM/DD",
      yzmmsg: "",
      count: 60,
      yzm: "",
      countries: countries["RECORDS"],
      states: states["RECORDS"],
      step: 0,
      tel: "",
      ycode: "",

      inpbmdate: [],

      memberdata: [],

      isEmpty: 0,

      checkshow: 0,

      wrgtel: false,

      wrgemail: false,
    };
  },
  mounted() {
    let that = this;
    that.inpbmdate = global.inpbmdate;

    // alert(1);

    // console.log("this.states:", this.states);
    let statesvalues = this.states.filter((item) => {
      return parseInt(item["country_id"]) == 44;
    });
    // console.log("statesvalues:", statesvalues);
    this.inpbmdate[3].values = statesvalues;
    this.inpbmdate[3].value = "";

    // that.inpbmdate[2].values = countries["RECORDS"];
    // console.log("countries:", this.countries);
  },
  methods: {
    moment,
    closebaoming() {
      this.$emit("closebm");
    },
    handleSelectChange() {
      console.log("inpbmdate:", this.inpbmdate);
    },
    handleCountrySelectChange() {
      let statesvalues = this.states.filter((item) => {
        return 44 == parseInt(this.inpbmdate[2].value);
      });
      console.log("statesvalues:", statesvalues);
      this.inpbmdate[3].values = statesvalues;
      this.inpbmdate[3].value = "";
    },
    goNext() {
      console.log("goNext");
      let that = this;
      if (that.memberdata.sex != "") {
        console.log(that.memberdata.sex);
        window.location.href = `#/pcenter/${this.lag}`;
        return false;
      }
      let step = this.step;
      if (step + 1 > 2) {
        window.location.href = `#/pcenter/${this.lag}`;
        return false;
      }
      this.step = step + 1;
    },

    showinfo($title, $message) {
      const h = this.$createElement;
      this.$info({
        class: "confirmDiv",
        title: $title,
        content: h("div", {}, [h("p", $message)]),
        onOk() {},
      });
    },
    submitlr() {
      let that = this;
      console.log("submitlr");
      console.log("bmid:", this.bmid);

      let wrongMsg = "";
      that.isEmpty = 0;
      that.inpbmdate.map((item, index) => {
        if (item.value == "" && item.required) {
          that.isEmpty++;
        }

        if (item.code == "tel") {
          if (!global.isMobile(item.value)) {
            that.isEmpty++;
            wrongMsg = "请填写有效的手机号码,";
            this.wrgtel = true;
          } else {
            this.wrgtel = false;
          }
        }
        if (item.code == "email") {
          if (!global.isEmail(item.value)) {
            that.isEmpty++;
            wrongMsg = "请填写有效的Email,";
            this.wrgemail = true;
          } else {
            this.wrgemail = false;
          }
        }
      });

      if (that.isEmpty > 0) {
        const h = this.$createElement;
        this.$info({
          title: "注意",
          content: h("div", {}, [h("p", `${wrongMsg}请完善表单数据！`)]),
          onOk() {},
        });
        that.checkshow = 1;
        return false;
      }

      let params = { mid: this.bmid, bkind: this.bkind, datas: that.inpbmdate };

      // console.log("params:", params);

      // return false;

      console.log("params:", params);
      addBm(params).then((res) => {
        console.log("addBm:", res);
        if (res.results.message == "1") {
          that.showinfo("您的参展意向已提交！", "");
          that.closebaoming();
        }
      });
    },
  },
};
</script>

<style scoped></style>
